import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const checkType = (type, t) => {
  let content;
  switch (type) {
    case 'terms':
      content = t(`website_terms_content`);
      return content;
    case 'privacypolicy':
      content = t(`website_privacy_content`);
      return content;
    default:
      return null;
  }
};

const Legalese = (props) => {
  const { t } = useTranslation();
  const { type } = props;
  const content = checkType(type, t);

  return (
    <div className="document-legalese">
      <div className="container">
        <Row justify="center">
          <Col xs={24} sm={24} md={16} lg={16}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

Legalese.propTypes = {
  t: PropTypes.func,
  type: PropTypes.string,
};

export default Legalese;
