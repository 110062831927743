import '../banners.less';
import './index.less';

// import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Title from '../../BannerShared/Title';

const LegalBanner = (props) => {
  // const { t } = useTranslation();
  const { title, description, tagline } = props;

  return (
    <div className="banner banner-legal">
      <div className="container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={24}>
            <section>
              {tagline && <Title title={tagline} isTagline isCenter />}
              <Title title={title} description={description} />
            </section>
          </Col>
        </Row>
      </div>
    </div>
  );
};

LegalBanner.propTypes = {
  t: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  tagline: PropTypes.string,
};

export default LegalBanner;
