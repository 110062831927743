import { lookup, navigatorLanguages } from '@wapps/langtag-utils';

import { navigate } from 'gatsby';

export const checkLanguage = (pageContext, location) => {
  if (!pageContext) return;
  const { fallbackLng, availableLngs, originalPath, redirectFallback } = pageContext;

  // lng check is handled by i18next plugin if redirect is set to true
  if (redirectFallback) return;

  // add urlParams so that redirect keeps params in mind
  let urlParams = '';
  let urlHash = '';
  if (location) {
    urlParams = location.search;
    urlHash = location.hash;
  }

  /*
    Steps/Priority:
    1. Check if @i18nextLng cookie is avail, if so set as user lng.
    2. Check if user's lng is available in list of lngs, if yes: set the lng, if not: fallback to en
    3. Set cookie for the detected & available lng based on the user. If not, default cookie to en
    4. Route the user accordingly, this is necessary as redirectFallback is set to false, and there are no prefixedroutes for fallbackLng, eg: /en/theworkout.
    */

  // NOTE for "en", the route will always get reset to root and cookie will set to "en", to mitigate this, have a section where user can select his lng pref where you can override the @i18nextLng cookie.
  const selectedLng = lookup(availableLngs, navigatorLanguages(), fallbackLng);

  if (selectedLng === fallbackLng && originalPath === '/') {
    const newUrl = `/${urlParams}${urlHash}`;
    navigate(newUrl, { replace: true });
  } else if (selectedLng === fallbackLng) {
    const newUrl = `${originalPath}${urlParams}${urlHash}`;
    navigate(newUrl, { replace: true });
  } else {
    const newUrl = `/${selectedLng}${originalPath}${urlParams}${urlHash}`;
    navigate(newUrl, { replace: true });
  }
};
