import React from 'react';
import PropTypes from 'prop-types';
import { Head } from '@tvai/gatsby-plugin-i18next';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';

// G: Refer to specification of OpenGraph here: https://ogp.me/#types

function SEO(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            author
            image
            imageWidth
            imageHeight
            imageType
            imageAltText
            keywords
          }
        }
      }
    `
  );

  const { t } = useTranslation();
  const { pageTitle } = props;
  const {
    title,
    url,
    description,
    image,
    imageHeight,
    imageWidth,
    imageType,
    imageAltText,
    author,
    keywords
  } = site.siteMetadata;

  return (
    <Head>
      {/* Required OG Metas */}
      <title>{`${t(`${pageTitle}`)}`}</title>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta
        name="description"
        property="og:description"
        content={description}
      />

      {/* Optional OG Metas */}
      <meta property="og:image:url" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:width" content={imageHeight} />
      <meta property="og:image:height" content={imageWidth} />
      <meta property="og:image:type" content={imageType} />
      <meta property="og:image:alt" content={imageAltText} />
      <meta property="keywords" content={keywords} />
      <meta property="author" content={author} />
      <meta
        property="fb:app_id"
        content={process.env.GATSBY_RITUAL_FB_APP_ID}
      />
    </Head>
  );
}

SEO.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default SEO;
