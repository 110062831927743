// @flow
import type { Node } from 'react';
import React from 'react';

import useSetupInfo from '../hooks/useSetupInfo';

export const SetupInfoContext = React.createContext<{
  isLoading: boolean,
  error: ?string,
  setupInfo: ?{
    Country_Code: string,
  },
  fetchSetupInfo: () => Promise<void>,
}>({});

type Props = {
  children?: Node,
};

export const SetupInfoProvider = ({ children }: Props) => {
  const { isLoading, error, setupInfo, fetchSetupInfo } = useSetupInfo();
  return (
    <SetupInfoContext.Provider value={{ isLoading, error, setupInfo, fetchSetupInfo }}>
      {children}
    </SetupInfoContext.Provider>
  );
};
